<template>
  <v-container>
    <v-row class="mt-6">
      <!-- TITULO -->
      <v-col class="text-center pb-0" cols="12">
        <span class="h3 semiBold">{{
          loading
            ? "Cargando datos del cliente..."
            : editMode
            ? "Editar datos del cliente"
            : "Registro del cliente"
        }}</span>
      </v-col>

      <!-- CONTENIDO -->
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="br-12 pa-6 ma-6" flat :loading="loading">
          <v-card-text class="pt-0">
            <component
              :docType="document_type"
              :docTypeDescription="docTypeDescription"
              :docNumber="document_number"
              ref="client"
              :is="formSelected?.component"
            >
              <template v-if="policy.applied" #policies-slot>
                <v-divider class="my-3"></v-divider>
                <!-- POLITICAS -->
                <div class="mt-3">
                  <div
                    v-for="item in avisos_privacidad"
                    :key="item._id"
                    class="d-flex align-center"
                  >
                    <v-checkbox
                      v-model="item.acepta_terminos"
                      :true-value="true"
                      :false-value="false"
                      class="mx-3 mt-0 policies-checkbox"
                      :rules="[
                        (v) =>
                          !!v || 'Debe aceptar la política para continuar.',
                      ]"
                    >
                      <template #label>
                        <p class="mb-0">
                          {{ item.prefijo_aviso_privacidad }}
                          <a
                            @click="
                              (policySelected = item), (dialog.policy = true)
                            "
                            >{{ item.titulo_aviso_privacidad }}</a
                          >
                        </p>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </template>
            </component>
          </v-card-text>
        </v-card>
        <div class="text-center my-10">
          <v-btn
            color="primary"
            outlined
            rounded
            class="text-none mr-4"
            @click="goBack"
            width="150"
            :disabled="loadingForm"
            >Volver</v-btn
          >
          <v-btn
            color="primary"
            rounded
            width="170"
            class="ml-4 text-none"
            :loading="loadingForm"
            id="client-form-submit"
            @click="save()"
            >{{ editMode ? "Guardar" : "Registrar" }}</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <CreateSuccessModal
      v-model="dialog.createModal"
      :docNumber="document_number"
      :docType="document_type"
      :docTypeDescription="docTypeDescription"
      @close="goForward()"
      @continue="goForward()"
    />

    <EditSuccessModal
      v-model="dialog.editModal"
      :docNumber="document_number"
      :docType="document_type"
      :docTypeDescription="docTypeDescription"
      @close="dialog.editModal = false"
      @continue="goForward()"
    />

    <!-- MODAL AVISOS DE PRIVACIDA -->
    <policy-modal
      v-model="dialog.policy"
      @close="dialog.policy = false"
      @submit="submitPolicy"
      :title="policySelected?.titulo_aviso_privacidad ?? ''"
      :policy="policySelected?.mensaje_aviso_privacidad ?? ''"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import CreateSuccessModal from "@/components/Modal/ClientDetail/Modal-New-Client.vue";
import EditSuccessModal from "@/components/Modal/ClientDetail/Modal-Edit-user.vue";
import policyModal from "@/components/Modal/privacyShowModal.vue";

import ChileForm from "@/views/Client/ClientChileForm.vue";
import PeruDNIForm from "@/views/Client/ClientPeruDNIForm.vue";
import PeruRUCForm from "@/views/Client/ClientPeruRUCForm.vue";
import PeruCEForm from "@/views/Client/ClientPeruCEForm.vue";

import CountryMixin from "@/Mixins/CountryMixin.js";
export default {
  name: "ClientInfoPage",
  mixins: [CountryMixin],
  props: {
    document_number: {
      type: String,
      required: true,
      description: "Numero de documento, parametro de cabecera.",
    },
    document_type: {
      type: String,
      required: true,
      description: "Tipo de documento, parametro de cabecera.",
    },
  },
  data: () => ({
    editMode: false,
    loading: false,
    loadingForm: false,
    dialog: {
      editModal: false,
      createModal: false,
      policy: false,
    },
    userData: null,
    personType: "",
    policySelected: null,
    avisos_privacidad: [],
  }),

  computed: {
    forms() {
      return [
        {
          id: 1,
          component: ChileForm,
          country: this.countryCodes["CL"],
          docType: "RUT",
        },
        {
          id: 2,
          component: PeruDNIForm,
          country: this.countryCodes["PE"],
          docType: "DNI",
        },
        {
          id: 3,
          component: PeruRUCForm,
          country: this.countryCodes["PE"],
          docType: "RUC",
        },
        {
          id: 4,
          component: PeruCEForm,
          country: this.countryCodes["PE"],
          docType: "CE",
        },
      ];
    },
    formSelected() {
      return this.forms.find(
        (e) =>
          e.country == this.country?.code && e.docType == this.document_type
      );
    },
    channel() {
      return this.$store.getters.getChannelSelected;
    },
    docTypeDescription() {
      return (
        this.country.docType.find((e) => e.codigo == this.document_type)
          ?.descripcion ?? this.document_type
      );
    },
    policy() {
      const {
        aplica_aviso_privacidad,
        avisos_de_privacidad,
        vigencia_aviso_privacidad,
      } = this.$store.getters.getAdvanceConfig;
      return {
        applied: aplica_aviso_privacidad ?? false,
        days: vigencia_aviso_privacidad ?? 1,
        notices: avisos_de_privacidad ?? [],
      };
    },
  },
  components: {
    CreateSuccessModal,
    EditSuccessModal,
    policyModal,

    ChileForm,
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapActions("Users", [
      "createUserNatural",
      "createUserJuridico",
      "editUserNatural",
      "editUserJuridico",
      "setClient",
    ]),

    async setup() {
      this.setupPolicies();
      await this.setupClient();
    },

    async setupClient() {
      this.loading = true;
      this.setClient({
        document_type: this.document_type,
        document_number: this.document_number,
      })
        .then((response) => {
          this.editMode = true;
          // Establecer el tipo de persona
          this.personType = response.tipo_persona;
          // Establecer los avisos de privacidad ya guardados
          this.selectClientPolicies(response?.avisos_privacidad);
          // Establecer la informacion del cliente
          this.$refs.client?.setFormData(response);
        })
        .catch(() => {
          if (this.personType) {
            //Se agrega para casos especificos
            this.editMode = true;
          } else {
            this.editMode = false;
            this.personType = "";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setupPolicies() {
      this.avisos_privacidad = this.policy.notices.map((e) => {
        return {
          ...e,
          acepta_terminos: false,
          id_canal_venta: this.channel.id,
          nombre_canal_venta: this.channel.description,
        };
      });
    },
    selectClientPolicies(data) {
      if (data) {
        data.forEach((element) => {
          const foundPolicy = this.avisos_privacidad.find(
            (e) => e._id === element._id
          );
          if (foundPolicy) {
            foundPolicy.acepta_terminos = element.acepta_terminos;
          }
        });
      }
    },
    submitPolicy() {
      const selected = this.avisos_privacidad.find(
        (e) => e._id === this.policySelected._id
      );
      if (selected) {
        selected.acepta_terminos = true;
      }
      this.dialog.policy = false;
    },

    goBack() {
      if (!this.editMode) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.goForward();
      }
    },
    goForward() {
      this.$router.push({
        name: "UserDetail",
        params: {
          document_number: this.document_number,
          document_type: this.document_type,
        },
      });
    },

    save() {
      const clientComponent = this.$refs.client;
      let isValid = clientComponent?.$refs.form?.validate();
      if (isValid) {
        this.loadingForm = true;
        const form = clientComponent.form;
        const formData = {
          ...form,
          numero_documento: this.document_number,
          tipo_documento: this.document_type,
          cod_pais: this.country.code,
          avisos_privacidad: this.avisos_privacidad,
        };
        let method;
        const dialog = this.editMode ? "editModal" : "createModal";
        // Se elige el metodo de Editado/Guardado segun la condicion
        const person = clientComponent.tipoPersona;
        if ((this.personType || person) == "NAT") {
          if (!this.editMode) {
            method = this.createUserNatural;
          } else {
            method = this.editUserNatural;
          }
        } else {
          if (!this.editMode) {
            method = this.createUserJuridico;
          } else {
            method = this.editUserJuridico;
          }
        }
        // Se ejecuta el metodo
        method(formData)
          .then(() => {
            this.dialog[dialog] = true;
          })
          .catch((e) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: e.message,
              top: true,
              right: true,
              color: "warning",
            });
          })
          .finally(() => {
            this.loadingForm = false;
          });
      }
    },
  },
};
</script>
