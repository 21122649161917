<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      width="40%"
      height="80%"
      attach="#main-container"
      class="submitted-client-data-modal"
      @click:outside="close()"
    >
      <v-card class="py-4">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold">¡Los datos han sido editados!</span>
            <v-btn icon color="error" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center mt-6 justify-center">
            <v-icon color="success" large>mdi-account-cog</v-icon>
            <span class="p ml-6">
              Los datos del cliente con
              <b> {{ docTypeDescription }}</b
              >-<span class="semiBold" v-if="docType == 'RUT'">{{
                docNumber | rutFormat
              }}</span>
              <span v-else class="semiBold">{{ docNumber }}</span>
              han sido modificados satisfactoriamente. Puede continuar
              cotizando.
            </span>
          </div>
          <div class="d-flex flex-column justify-center align-center my-6">
            <v-btn
              color="primary"
              class="text-none mt-4"
              id="submittedClientDataBtn"
              width="214"
              height="41"
              rounded
              @click="goToClient()"
              >Ir a cotizar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ClientEditSuccessModal",
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    docNumber: {
      default: "",
      type: String,
    },
    docType: {
      default: "",
      type: String,
    },
    docTypeDescription: {
      default: "",
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    goToClient() {
      this.$emit("continue");
    },
  },
};
</script>
